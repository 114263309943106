/**
 * Returns the days offset for a specified time in UTC and a timezone.
 * E.g for assume those timestamps:
 *   t1 = 1548504610000; // 2019-01-26 12:10:10 UTC
 *   t2 = 1548544210000; // 2019-01-26 23:10:10 UTC
 *   t3 = 1548634210000; // 2019-01-28 00:10:10 UTC
 *
 * getDaysOffset(t1, t2, 'Europe/London'); // returns 0
 * getDaysOffset(t1, t2, 'Europe/Rome'); // returns 1 because t2 overflows to next day
 * getDaysOffset(t1, t3, 'Europe/London'); // returns 2
 * getDaysOffset(t1, t3, 'America/Chicago'); // returns 1 because t2 leaks to previous day
 *
 */
export const getDaysOffset = (fromTimeUtc, toTimeUtc, timezone) => {
  // Get the timezone sensitive offset (in hours) of "from time" and "to time".
  const fromTimeOffset = Number(
    new Date(fromTimeUtc - (fromTimeUtc % 86400000)).toLocaleString("en-GB", {
      hour: "2-digit",
      hour12: false,
      timeZone: timezone
    })
  );
  const toTimeOffset = Number(
    new Date(toTimeUtc - (toTimeUtc % 86400000)).toLocaleString("en-GB", {
      hour: "2-digit",
      hour12: false,
      timeZone: timezone
    })
  );
  // Adjust offset (hijack the UTC specific variables).
  fromTimeUtc = fromTimeUtc + fromTimeOffset * 1000 * 60 * 60;
  toTimeUtc = toTimeUtc + toTimeOffset * 1000 * 60 * 60;
  // Calculate the day (from unix epoch) indexes.
  const fromDayIndex = Math.floor(fromTimeUtc / 1000 / 60 / 60 / 24);
  const toDayIndex = Math.floor(toTimeUtc / 1000 / 60 / 60 / 24);
  // Return days distance.
  return toDayIndex - fromDayIndex;
};
