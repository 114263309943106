import { $, $$, $child, create, empty } from './dom.js';
import { getFavouritesFull } from './lisStorageUtils.js';
import escapeHtml from 'escape-html';
import { getDaysOffset } from './date.js';

let infoVisible = false;

const renderSessionTypeMarkup = (sessionType, element = 'span') =>
  `<${element} class="session-type-${sessionType.class}"><span>${escapeHtml(sessionType.name)}</span></${element}>`;
const renderPresentationTypeMarkup = (presentationType, sessionType, element = 'span') =>
  `<${element} class="session-type-${sessionType.class}"><span>${escapeHtml(presentationType.name)}</span></${element}>`;

const createFavouriteDom = favourite => {
  const sessionType = window.lis.sessionTypes.find(sessionType => sessionType.id === favourite.sessionTypeId);
  const presentationType = window.lis.presentationTypes.find(presentationType => presentationType.id === favourite.presentationTypeId);
  const speakers = favourite.speakers
    ? favourite.speakers.map(speakerId => window.lis.speakers.find(speaker => speaker.id === speakerId))
    : undefined;
  return create(
    `<a href="${favourite.url}">
  <article>
    <div class="${favourite.type}">
    ${favourite.type === "session" && sessionType ? renderSessionTypeMarkup(sessionType) : ``}
    ${favourite.type === "presentation" && presentationType ? renderPresentationTypeMarkup(presentationType, sessionType) : ``}
    ${favourite.subtitle ? `<p class="subtitle">${escapeHtml(favourite.subtitle)}</p>` : ``}
    <h3>${escapeHtml(favourite.title)}</h3>
    <span class="date">
      <time>
        ${new Date(favourite.startUtc * 1000).toLocaleString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: window.lis.systemTimezone,
    })}
      </time>
      -
      <time>
        ${new Date(favourite.endUtc * 1000).toLocaleString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: window.lis.systemTimezone,
    })}
      </time>
    </span>
    ${favourite.room ? `<span class="room">${escapeHtml(favourite.room)}</span>` : ``}
    ${
      speakers
        ? `<span class="speakers">${speakers.map(
          speaker =>
            `${speaker.title ? escapeHtml(speaker.title) : ''}${escapeHtml(speaker.firstName)} ${escapeHtml(
              speaker.lastName,
            )}`,
        )}</span>`
        : ``
    }
    </div>
  </article>
</a>`,
  );
};

// Calculate groups.
const groupsDefault = window.lis.presentations.map(presentation => {
  const daysOffset = getDaysOffset(
    window.lis.presentations[0].startUtc * 1000, // We know that the presentations are rendered in ascending order
    presentation.startUtc * 1000,
    window.lis.systemTimezone,
  );
  presentation.daysOffset = daysOffset;
  return daysOffset;
});
const groupsSessions = window.lis.sessions.map(session => {
  const daysOffset = getDaysOffset(
    window.lis.sessions[0].startUtc * 1000, // We know that the presentations are rendered in ascending order
    session.startUtc * 1000,
    window.lis.systemTimezone,
  );
  session.daysOffset = daysOffset;
  return daysOffset;
});

const createMenuItem = () => {
  const $menuItem = create(`<a href="#/menu/my-favourites" class="UserMenuFavourites">My favourites</a>`);
  const $menuScreen = create(
    `<div class="UserMenuFavourites">
  <h2>My favourites</h2>
  <section class="info">
    <a href="#/menu/my-favourites">Info</a>
    <ul>
    ${window.lis.sessionTypes.map(sessionType => renderSessionTypeMarkup(sessionType, 'li')).join(``)}
    </ul>
  </section>
  <div>
    <ul class="groups">
      <li data-group="all"><a href="#/menu/my-favourites">All</a></li>
      ${groupsDefault
      .filter((value, index, self) => self.indexOf(value) === index)
      .map(
        group => `<li data-group="${group}"><a href="#/menu/my-favourites/${group + 1}">
          <span class="weekDay">${new Date(
          window.lis.presentations[0].startUtc * 1000 + group * 1000 * 60 * 60 * 24,
        ).toLocaleString('en-GB', {
          weekday: 'short',
          timeZone: window.lis.systemTimezone,
        })}</span>
          <span class="day">${new Date(
          window.lis.presentations[0].startUtc * 1000 + group * 1000 * 60 * 60 * 24,
        ).toLocaleString('en-GB', {
          day: 'numeric',
          timeZone: window.lis.systemTimezone,
        })}</span>
        <span class="month">${new Date(
          window.lis.presentations[0].startUtc * 1000 + group * 1000 * 60 * 60 * 24,
        ).toLocaleString('en-GB', {
          month: 'short',
          timeZone: window.lis.systemTimezone,
        })}</span>
        </a></li>`,
      )
      .join(``)}
    </ul>
    <h2>Sessions</h2>
    <div class="sessions"></div>
    <h2>Presentations</h2>
    <div class="presentations"></div>
  </div>
</div>`,
  );
  const $info = $child('.info', $menuScreen);
  const $favourites = $child('div', $menuScreen);
  const $groups = $child('.groups', $favourites);
  const $favouritesListSessions = $child('.sessions', $favourites);
  const $favouritesListPresentations = $child('.presentations', $favourites);

  $info.addEventListener('click', e => {
    e.preventDefault();
    infoVisible = !infoVisible;
    updateScreenState();
  });

  const getSelectedDayOffsetFromUrlHash = () => {
    const match = window.location.hash.match(/#\/menu\/my-favourites\/([0-9]+)/);
    return !match ? undefined : Number(match[1]) - 1;
  };
  const updateScreenState = () => {
    // Show/hide info box.
    if (infoVisible) {
      $info.classList.add('visible');
    } else {
      $info.classList.remove('visible');
    }

    // Update groups class.
    const selectedDayOffset = getSelectedDayOffsetFromUrlHash();
    $$('li', $groups).forEach($group => {
      if (
        ($group.dataset.group === 'all' && selectedDayOffset === undefined) ||
        parseInt($group.dataset.group) === selectedDayOffset
      ) {
        $group.classList.add('current');
      } else {
        $group.classList.remove('current');
      }
    });

    // Get all favourites and sort them by start date.
    // Subsequent processing assumes that favourites are sorted.
    const favourites = Object.values(getFavouritesFull()).sort((a, b) => a.startUtc - b.startUtc);

    // Render all or filtered session's favourites.
    empty($favouritesListSessions);
    console.log(favourites);
    ;
    favourites
      .filter(favourite => favourite.type === 'session')
      .filter(favourite => selectedDayOffset === undefined || selectedDayOffset === favourite.daysOffset)
      .forEach(favourite => {
        $favouritesListSessions.appendChild(createFavouriteDom(favourite));
      });
    // Hacky way to detect whether we added any favourites in the previous step.
    if (!$favouritesListSessions.hasChildNodes()) {
      $favouritesListSessions.appendChild(
        create(
          selectedDayOffset === undefined
            ? `<p class="empty">No favourite sessions added.</p>`
            : `<p class="empty">No favourite sessions added for this day.</p>`,
        ),
      );
    }
    // Render all or filtered presentation's favourites.
    empty($favouritesListPresentations);
    favourites
      .filter(favourite => favourite.type === 'presentation')
      .filter(favourite => selectedDayOffset === undefined || selectedDayOffset === favourite.daysOffset)
      .forEach(favourite => {
        $favouritesListPresentations.appendChild(createFavouriteDom(favourite));
      });
    // Hacky way to detect whether we added any favourites in the previous step.
    if (!$favouritesListPresentations.hasChildNodes()) {
      $favouritesListPresentations.appendChild(
        create(
          selectedDayOffset === undefined
            ? `<p class="empty">No favourite presentations added.</p>`
            : `<p class="empty">No favourite presentations added for this day.</p>`,
        ),
      );
    }
  };

  return {
    menuItem: $menuItem,
    updateItemState: () => {},
    menuScreen: $menuScreen,
    updateScreenState: updateScreenState,
  };
};

export default createMenuItem;
