import { $, $$, create } from "./dom.js";
import { getNodeId } from "./lisDomUtils";
import { isFavourite, favourite, unfavourite } from "./lisStorageUtils";

const apply = () => {
  $$(".node-presentation").forEach(presentation => applyFavouriteButton(presentation));
  $$(".node-type-session article").forEach(session => applyFavouriteButton(session));
  $$(".view-sessions .node-session").forEach(session => applyFavouriteButton(session));
};
export default apply;

const applyFavouriteButton = node => {
  const id = getNodeId(node);
  if (id === undefined) {
    console.warn("Node without an id?");
    return;
  }

  console.log(`Applying favourite button on node: ${id}`);

  // component state
  let isNodeFavourite = isFavourite(id);

  const $button = create(`<a href="#" class="FavouriteButton"><span></span></a>`);

  const updateButtonState = () => {
    $("span", $button).className = `icon-${isNodeFavourite ? "unfavourite" : "favourite"}`;
    $("span", $button).innerHTML = isNodeFavourite ? "Unfavourite" : "Favourite";
  };

  $button.addEventListener("click", e => {
    e.preventDefault();
    isNodeFavourite = !isNodeFavourite;
    updateButtonState();
    if (isNodeFavourite) {
      favourite(id);
    } else {
      unfavourite(id);
    }
  });
  updateButtonState();
  node.getElementsByClassName('node-title')[0].appendChild($button);
};
