import { $, create } from "./dom.js";
import { areUrgentNotificationsEnabled, setUrgentNotifications } from "./lisStorageUtils.js";

const createMenuItem = () => {
  const $menuItem = create(`<a href="#/menu/settings" class="UserMenuSettings">Settings</a>`);
  const $menuScreen = create(`<div class="UserMenuSettings">
  <h2>Settings</h2>
  <fieldset>
    <legend>Profile</legend>
    <a href="#/menu/my-profile">Edit my profile</a>
  </fieldset>
  <fieldset>
    <legend>Notifications</legend>
    <label class="notifications">Enable urgent notification pop-up: <input type="checkbox" /></label>
  </fieldset>
</div>`);
  const $notificationsCheckbox = $(".notifications input", $menuScreen);
  $notificationsCheckbox.addEventListener("change", e => setUrgentNotifications($notificationsCheckbox.checked));

  return {
    menuItem: $menuItem,
    updateItemState: () => {},
    menuScreen: $menuScreen,
    updateScreenState: () => {
      $notificationsCheckbox.checked = areUrgentNotificationsEnabled();
    }
  };
};

export default createMenuItem;
