import { $, $child, create, empty } from "./dom.js";
import { getFullNameMarkup } from "./lisProfileUtils.js";
import { getMyContacts } from "./lisStorageUtils.js";
import { getProfileView } from "./lisProfileUtils.js";

const createContactDom = (profileData, index) =>
  create(`<li><article><a href="#/menu/my-contacts/${index + 1}">${getFullNameMarkup(profileData)}</a></article></li>`);

const getSelectedContactIndexFromUrlHash = () => {
  const match = window.location.hash.match(/#\/menu\/my-contacts\/([0-9]+)/);
  return !match ? undefined : Number(match[1]) - 1;
};

const createMenuItem = () => {
  const $menuItem = create(`<a href="#/menu/my-contacts" class="UserMenuMyContacts">My contacts</a>`);
  const $menuScreen = create(`<div class="UserMenuMyContacts">
  <h2>My contacts</h2>
  <div></div>
</div>`);
  const $screen = $child("div", $menuScreen);

  return {
    menuItem: $menuItem,
    updateItemState: () => {},
    menuScreen: $menuScreen,
    updateScreenState: () => {
      const contacts = getMyContacts();
      const selectedContactIndex = getSelectedContactIndexFromUrlHash();
      empty($screen);
      if (contacts[selectedContactIndex]) {
        // View selected profile.
        $screen.appendChild(getProfileView(contacts[selectedContactIndex], { download: true, remove: true }));
      } else if (contacts.length === 0) {
        // No contacts.
        $screen.appendChild(create(`<p class="empty">No contacts scanned yet.</p>`));
      } else {
        // List contacts.
        const $contactsList = create("<ol></ol>");
        $screen.appendChild($contactsList);
        contacts.forEach((contact, index) => $contactsList.appendChild(createContactDom(contact, index)));
      }
    }
  };
};

export default createMenuItem;
