import { $, $child, create, empty } from "./dom.js";
import escapeHtml from "escape-html";
import { isNotificationRead, readNotification, getReadNotifications } from "./lisStorageUtils.js";

const createNotificationDom = notification =>
  create(
    `<article data-id="${notification.id}" class="${isNotificationRead(notification.id) ? "read" : ""}">
  <header>
    ${notification.urgent ? `<p class="urgent">URGENT</p>` : ``}
    <h3><a href="#/menu/notifications">${escapeHtml(notification.title)}</a></h3>
  </header>
  <div>${notification.body}</div>
  <p class="date">${new Date(notification.date * 1000).toLocaleString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: window.lis.systemTimezone
  })}</p>
</article>`
  );

const createMenuItem = () => {
  const $menuItem = create(
    `<a href="#/menu/notifications" class="UserMenuNotifications">Notifications <span class="count"></span></a>`
  );
  const $count = $("span", $menuItem);
  const $menuScreen = create(`<div class="UserMenuNotifications">
  <h2>Notifications</h2>
  <div></div>
</div>`);
  const $notificationsList = $child("div", $menuScreen);

  $notificationsList.addEventListener("click", e => {
    e.preventDefault();
    if (
      e.target.nodeName.toLowerCase() === "a" &&
      e.target.parentNode.nodeName.toLowerCase() === "h3" &&
      e.target.parentNode.parentNode.nodeName.toLowerCase() === "header" &&
      e.target.parentNode.parentNode.parentNode.nodeName.toLowerCase() === "article"
    ) {
      const $article = e.target.parentNode.parentNode.parentNode;
      if ($article.classList.contains("expanded")) {
        $article.classList.remove("expanded");
      } else {
        $article.classList.add("expanded");
        $article.classList.add("read");
        readNotification(parseInt($article.dataset.id));
      }
    }
  });

  return {
    menuItem: $menuItem,
    updateItemState: () => {
      const readNotificationIds = Object.keys(getReadNotifications()).map(id => parseInt(id));
      const unreadNotificationsCount = window.lis.notifications
        .map(notification => notification.id)
        .filter(id => !readNotificationIds.includes(id)).length;
      if (unreadNotificationsCount) {
        $count.innerHTML = unreadNotificationsCount;
        $count.style.display = "inline";
      } else {
        $count.innerHTML = "";
        $count.style.display = "none";
      }
    },
    menuScreen: $menuScreen,
    updateScreenState: () => {
      empty($notificationsList);
      if (window.lis.notifications.length === 0) {
        $notificationsList.appendChild(create(`<p class="empty">No notifications yet.</p>`));
      } else {
        window.lis.notifications.forEach(notification => {
          $notificationsList.appendChild(createNotificationDom(notification));
        });
      }
    }
  };
};

export default createMenuItem;
