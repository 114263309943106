import { $, create, empty } from "./dom.js";
import { hasProfileData, getProfileData, setProfileData } from "./lisStorageUtils.js";
import {
  facebookBase,
  sanitizeFacebookUrl,
  twitterBase,
  sanitizeTwitterUrl,
  linkedinBase,
  sanitizeLinkedinUrl,
  getFullNameMarkup,
  getProfileView
} from "./lisProfileUtils.js";
import { isIos, isInStandaloneMode } from "./lisDomUtils.js";

import escapeHtml from "escape-html";

const isInEdit = () => window.location.hash.startsWith("#/menu/my-profile/edit");
const isInView = () => !isInEdit();

const generateProfileData = (
  firstname,
  lastname,
  position,
  company,
  location,
  email,
  phone,
  facebook,
  twitter,
  linkedin,
  website
) => {
  // Mandatory fields.
  const profileData = {
    firstname: firstname.trim().substr(0, 32),
    lastname: lastname.trim().substr(0, 32)
  };
  // Optional fields.
  if (position.length > 0) profileData.position = position.trim().substr(0, 64);
  if (company.length > 0) profileData.company = company.trim().substr(0, 64);
  if (location.length > 0) profileData.location = location.trim().substr(0, 64);
  if (email.length > 0) profileData.email = email.trim().substr(0, 64);
  if (phone.length > 0) profileData.phone = phone.trim().substr(0, 64);
  if (facebook.length > 0) profileData.facebook = sanitizeFacebookUrl(facebook.trim().substr(0, 64));
  if (twitter.length > 0) profileData.twitter = sanitizeTwitterUrl(twitter.trim().substr(0, 64));
  if (linkedin.length > 0) profileData.linkedin = sanitizeLinkedinUrl(linkedin.trim().substr(0, 64));
  if (website.length > 0) profileData.website = website.trim().substr(0, 64);
  return profileData;
};

// This allows " ab" and " a b " but prevents "  a" and "    ".
// Regex translates to:
// ^   = Start of match.
// \s* = 0 to many whitespace characters.
// \S  = 1 non whitespace character.
// .*  = 0 to many characters.
// \S  = 1 non whitespace character.
// \s* = 0 to many whitespace characters.
// $   = End of match.
const mandatoryTwoCharactersExcludingWrappingWhitespace = "^\\s*\\S.*\\S\\s*$";
const mandatoryPattern = mandatoryTwoCharactersExcludingWrappingWhitespace;

const createViewScreen = () =>
  getProfileView(getProfileData(), {
    edit: true,
    qr: true,
    scan: !(isIos() && isInStandaloneMode()), // Scan not possible when in iOS installed PWA. LIS-107
    download: true
  });

const createEditScreen = () => {
  const profileData = getProfileData();
  const $screen = create(
    `<div class="edit">
  <h2>Edit profile</h2>
  <p>Your profile information is stored locally on your device.
  You can choose to share it with other participants via the QR code.
  More information on <a href="${window.lis.basePath}legal-notice-and-policies">Privacy policy</a>.</p>
  <form>
    <label class="firstname">First name <span class="mandatory">*</span>
      <input
        type="text"
        name="firstname"
        pattern="${mandatoryPattern}"
        maxlength="32"
        required
        value="${escapeHtml(profileData["firstname"] || "")}"
        title="2 characters minimum"
      />
    </label>
    <label class="lastname">Last name <span class="mandatory">*</span>
      <input
        type="text"
        name="lastname"
        pattern="${mandatoryPattern}"
        maxlength="32"
        required
        value="${escapeHtml(profileData["lastname"] || "")}"
        title="2 characters minimum"
      />
    </label>
    <label class="position">Position
      <input
        type="text"
        name="position"
        maxlength="64"
        value="${escapeHtml(profileData["position"] || "")}"
      />
    </label>
    <label class="company">Company/Organisation
      <input
        type="text"
        name="company"
        maxlength="64"
        value="${escapeHtml(profileData["company"] || "")}"
      />
    </label>
    <label class="location">Location
      <input
        type="text"
        name="location"
        maxlength="64"
        value="${escapeHtml(profileData["location"] || "")}"
      />
    </label>
    <label class="email">Email
      <input
        type="email"
        name="email"
        maxlength="64"
        value="${escapeHtml(profileData["email"] || "")}"
      />
    </label>
    <label class="phone">Phone
      <input
        type="text"
        name="phone"
        maxlength="64"
        value="${escapeHtml(profileData["phone"] || "")}"
      />
    </label>
    <label class="facebook">Facebook
      <input
        type="text"
        name="facebook"
        maxlength="64"
        value="${escapeHtml(profileData["facebook"] ? facebookBase + profileData["facebook"] : "")}"
      />
    </label>
    <label class="twitter">Twitter
      <input
        type="text"
        name="twitter"
        maxlength="64"
        value="${escapeHtml(profileData["twitter"] ? twitterBase + profileData["twitter"] : "")}"
      />
    </label>
    <label class="linkedin">Linkedin
      <input
        type="text"
        name="linkedin"
        maxlength="64"
        value="${escapeHtml(profileData["linkedin"] ? linkedinBase + profileData["linkedin"] : "")}"
      />
    </label>
    <label class="website">Website
      <input
        type="text"
        name="website"
        maxlength="64"
        value="${escapeHtml(profileData["website"] || "")}"
      />
    </label>
    <button type="submit" class="submit">Save</button>
    <a href="${hasProfileData() ? "#/menu/my-profile" : "#/menu"}" class="button cancel">Cancel</a>
  </form>
</div>`
  );
  $("form", $screen).addEventListener("submit", e => {
    e.preventDefault();
    console.log("Submitting profile data. Moving to view profile.");
    setProfileData(
      generateProfileData(
        $('input[name="firstname"]', $screen).value,
        $('input[name="lastname"]', $screen).value,
        $('input[name="position"]', $screen).value,
        $('input[name="company"]', $screen).value,
        $('input[name="location"]', $screen).value,
        $('input[name="email"]', $screen).value,
        $('input[name="phone"]', $screen).value,
        $('input[name="facebook"]', $screen).value,
        $('input[name="twitter"]', $screen).value,
        $('input[name="linkedin"]', $screen).value,
        $('input[name="website"]', $screen).value
      )
    );
    window.location.hash = "/menu/my-profile";
  });
  return $screen;
};

const createMenuItem = () => {
  const $menuItem = create(`<a href="#/menu/my-profile" class="UserMenuProfile"></a>`);
  const $menuScreen = create(`<div class="UserMenuProfile"></div>`);

  return {
    menuItem: $menuItem,
    updateItemState: () => {
      $menuItem.innerHTML = hasProfileData() ? getFullNameMarkup(getProfileData()) : "My profile";
    },
    menuScreen: $menuScreen,
    updateScreenState: () => {
      if (!hasProfileData() && isInView()) {
        console.log("Moving to edit profile because profile hasn't been filled in.");
        window.location.hash = "/menu/my-profile/edit";
        return;
      }
      empty($menuScreen);
      $menuScreen.appendChild(isInView() ? createViewScreen() : createEditScreen());
    }
  };
};

export default createMenuItem;
