import { $, create, empty } from "./dom.js";
import { parseVcardToProfileData, isVcard, getProfileView } from "./lisProfileUtils.js";
import jsQR from "jsqr";

const detectWebcam = callback => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) return callback(false);
  navigator.mediaDevices.enumerateDevices().then(devices => {
    callback(devices.some(device => "videoinput" === device.kind));
  });
};

const createMenuItem = () => {
  const $menuItem = create(`<a href="#/menu/scan-contacts" class="UserMenuScanContacts">Scan contacts</a>`);
  const $menuScreen = create(`<div class="UserMenuScanContacts">
  <h2>Scan contacts</h2>
</div>`);
  const $menuScreenContent = create(`<div />`);
  $menuScreen.appendChild($menuScreenContent);

  const $video = create("<video />");
  const $canvas = document.createElement("canvas");
  const canvasContext = $canvas.getContext("2d");
  let capture = false;

  const stopCapture = () => {
    capture = false;
    if ($video && $video.srcObject) {
      console.log("Stop capturing video");
      $video.srcObject.getTracks().forEach(track => track.stop());
      empty($menuScreenContent);
    }
  };

  const startCapture = () => {
    navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: "environment" } } }).then(
      stream => {
        capture = true;
        $menuScreenContent.appendChild($video);
        $video.srcObject = stream;
        $video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen // https://cozmo.github.io/jsQR/
        $video.play();
        requestAnimationFrame(processVideoFrame);
      },
      // Error Callback
      function(err) {
        console.error(err.name, err.message);
        empty($menuScreenContent);
        $menuScreenContent.appendChild(
          create(
            '<p class="warning">Camera access not given. In order to scan contacts please allow camera permission and try again.</p>'
          )
        );
      }
    );
  };

  const processVideoFrame = () => {
    if ($video.readyState === $video.HAVE_ENOUGH_DATA) {
      console.log(`Processing captured frame ${$video.videoWidth} x ${$video.videoHeight}`);
      $canvas.width = $video.videoWidth;
      $canvas.height = $video.videoHeight;
      canvasContext.drawImage($video, 0, 0, $video.videoWidth, $video.videoHeight);
      const imageData = canvasContext.getImageData(0, 0, $video.videoWidth, $video.videoHeight);
      const code = jsQR(imageData.data, $video.videoWidth, $video.videoHeight, {
        inversionAttempts: "dontInvert"
      });
      if (code && code.data) {
        codeCaptured(code.data);
      }
    }
    if (capture) requestAnimationFrame(processVideoFrame);
  };

  const codeCaptured = code => {
    console.log(`Captured code: ${code}`);
    stopCapture();
    empty($menuScreenContent);
    if (!isVcard(code)) {
      $menuScreenContent.appendChild(
        create(`<div>
  <p class="warning">Scanned QR code data not in vCard format. Please try again.</p>
  <button class="button">Scan</button>
</div>`)
      );
      $("button", $menuScreenContent).addEventListener("click", start);
      return;
    }

    const profileData = parseVcardToProfileData(code);
    $menuScreenContent.appendChild(
      create(`<div>
  <p class="success">QR code scanned. Preview of the contact:</p>
  <fieldset>
  </fieldset>
</div>`)
    );
    $("fieldset", $menuScreenContent).appendChild(getProfileView(profileData, { download: true, save: true }));
  };

  const start = () => {
    empty($menuScreenContent);
    detectWebcam(hasWebcam => {
      if (!hasWebcam) {
        $menuScreenContent.appendChild(
          create('<p class="warning">Camera not found. Please try with a different device.</p>')
        );
        return;
      } else {
        startCapture();
      }
    });
  };

  return {
    menuItem: $menuItem,
    updateItemState: () => {},
    menuScreen: $menuScreen,
    updateScreenState: start,
    screenHidden: stopCapture
  };
};

export default createMenuItem;
