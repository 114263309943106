import { $, $$, create } from "./dom.js";
import escapeHtml from "escape-html";
import createMenuProfile from "./userMenuProfile.js";
import createMenuNotifications from "./userMenuNotifications.js";
import createMenuFavourites from "./userMenuFavourites.js";
import createMenuScanContacts from "./userMenuScanContacts.js";
import createMenuMyContacts from "./userMenuMyContacts.js";
import createMenuSettings from "./userMenuSettings.js";
import { setLastMenuScrollTop, getLastMenuScrollTop, setLastMenuPage, getLastMenuPage } from "./lisStorageUtils.js";
import { isIos, isInStandaloneMode } from "./lisDomUtils.js";

const isMenuVisible = () => window.location.hash.startsWith("#/menu");
const isMenuItemVisible = menuItem => {
  return window.location.hash.startsWith(menuItem.menuItem.getAttribute("href"));
};

const $button = create(`<a href="#" class="UserMenuButton"><span>MENU</span></a>`);

const $menu = create(`<section class="UserMenu">
  <a class="back" href="#/menu">Back</a>
  <h3>${escapeHtml(window.lis.siteName)}</h3>
  <ul>
  </ul>
</section>`);

const $menuBack = $("a", $menu);
const $menuHeader = $("h3", $menu);
const $menuLinks = $("ul", $menu);
const menuItems = !(isIos() && isInStandaloneMode())
  ? // Normal menu
    [
      createMenuProfile(),
      createMenuNotifications(),
      createMenuFavourites(),
      createMenuScanContacts(),
      createMenuMyContacts(),
      createMenuSettings()
    ]
  : // iOS installed PWA menu
    [
      createMenuProfile(),
      createMenuNotifications(),
      createMenuFavourites(),
      // createMenuScanContacts(), // Scan not possible when in iOS installed PWA. LIS-107
      // createMenuMyContacts(), // Hide this as well when in iOS installed PWA. LIS-107
      createMenuSettings()
    ];
const updateState = () => {
  // Handle menu visibility.
  if (isMenuVisible()) {
    $button.classList.add("pressed");
    $menu.classList.add("visible");
    $button.href = "#";
  } else {
    $button.classList.remove("pressed");
    $menu.classList.remove("visible");
    $button.href = "#/menu";
  }
  // Handle menu items update.
  menuItems.forEach(menuItem => {
    menuItem.updateItemState();
  });

  // Handle menu screens visibility.
  let menuShouldBeVisible = true;
  menuItems.forEach(menuItem => {
    if (isMenuItemVisible(menuItem)) {
      menuItem.menuScreen.style.display = "block";
      menuShouldBeVisible = false;
      menuItem.updateScreenState();
    } else {
      menuItem.menuScreen.style.display = "none";
      menuItem.screenHidden && menuItem.screenHidden();
    }
  });
  $menuBack.style.display = menuShouldBeVisible ? "none" : "block";
  $menuHeader.style.display = menuShouldBeVisible ? "block" : "none";
  $menuLinks.style.display = menuShouldBeVisible ? "block" : "none";
};

const hideMenu = () => {
  window.history.pushState(null, null, " ");
  updateState();
};

const createAndAttach = () => {
  // Handle click just for the close menu case to clear hash from URL.
  $button.addEventListener("click", e => {
    if (isMenuVisible()) {
      e.preventDefault();
      hideMenu();
    }
  });

  // Update state whenever the hash changes. This can happen either from clicking
  // a link with anchor, or via back and forth.
  window.addEventListener("hashchange", updateState);

  // Handle clicking outside of menu area.
  window.document.addEventListener(
    "mousedown",
    e => {
      if (isMenuVisible() && !$menu.contains(e.target) && !$button.contains(e.target)) {
        e.preventDefault();
        hideMenu();
      }
    },
    false
  );

  // Attach menu items and behavior to menu.
  menuItems.forEach(menuItem => {
    const $li = create("<li></li>");
    $li.appendChild(menuItem.menuItem);
    $("ul", $menu).appendChild($li);
  });

  // Attach menu screens and behavior to menu.
  menuItems.forEach(menuItem => {
    $menu.appendChild(menuItem.menuScreen);
  });

  // Update state just in case the app was loaded with menu visible.
  updateState();

  // Attach elements to dom.
  $("body").prepend($button);
  $("body").prepend($menu);

  // Retain scroll position in case we render the previously visible menu page.
  if (isMenuVisible() && window.location.hash === getLastMenuPage() && getLastMenuScrollTop() > 0) {
    $menu.scrollTo({ top: getLastMenuScrollTop(), left: 0 });
  }

  // Keep track of last menu scroll position when changing server page.
  window.addEventListener("beforeunload", () => {
    if (isMenuVisible()) {
      setLastMenuScrollTop($menu.scrollTop);
      setLastMenuPage(window.location.hash);
    }
  });
};
export default createAndAttach;
