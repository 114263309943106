import applyUnreadNotificationsModal from "./unreadNotificationsModal.js";

let pollingDelaySecs = 1;
const pollingDelaySecsMax = 30;
const pollingRetrySecsNoNetwork = 2;

// Implements a rampup detail in order to prevent server flooding.
const getNextPollingDelay = () => {
  pollingDelaySecs *= 2;
  if (pollingDelaySecs > pollingDelaySecsMax) pollingDelaySecs = pollingDelaySecsMax;
  return pollingDelaySecs * 1000;
};

const apply = () => {
  const refresh = () => {
    if (!window.navigator.onLine) {
      // Retry a bit later.
      setTimeout(refresh, pollingRetrySecsNoNetwork * 1000);
      return;
    }
    console.log("Polling server for new notifications");
    fetch(`${window.lis.basePath}api/notifications`)
      .then(response => {
        return response.json();
      })
      .then(notifications => {
        setTimeout(refresh, getNextPollingDelay());

        // Don't bother if there are no notifications from the server.
        if (notifications.length === 0) return;

        // We know the notifications are sorted desc.
        const newNotificationsLatestId = notifications[0].id;
        const notificationsLatestId = window.lis.notifications[0].id;
        if (newNotificationsLatestId > notificationsLatestId) {
          // There is a new notification pulled from the server. Replace what we
          // have on window and rerun modal logic.
          window.lis.notifications = notifications;
          applyUnreadNotificationsModal();
        }
      })
      .catch(error => {
        console.log("error", error);
        setTimeout(refresh, getNextPollingDelay());
      });
  };
  setTimeout(refresh, getNextPollingDelay());
};
export default apply;
