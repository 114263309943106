// Just import app.css so it's processed by parcel.js.
import "./app.css";
import isTouchDevice from "is-touch-device";
import { register } from "register-service-worker";
import applyFavouriteButtons from "./favouriteButton.js";
import applyUnreadNotificationsModal from "./unreadNotificationsModal.js";
import applyNotificationsPolling from "./notificationsPolling.js";
import applyFavouritesNotificationsModal from "./favouritesNotificationsModal.js";
import addToHomeScreenOnIosModal from "./addToHomeScreenOnIosModal.js";
import createUserMenu from "./userMenu.js";

const apply = () => {
  applyFavouriteButtons();
  applyUnreadNotificationsModal();
  applyNotificationsPolling();
  applyFavouritesNotificationsModal();
  createUserMenu();
  addToHomeScreenOnIosModal();

  // Used https://www.npmjs.com/package/register-service-worker to register service worker
  // because doing it via JS made parcel generate wrong URL:
  // - https://github.com/parcel-bundler/parcel/issues/1205
  // - https://github.com/parcel-bundler/parcel/issues/144
  // - https://github.com/parcel-bundler/parcel/issues/331
  // - https://github.com/parcel-bundler/parcel/issues/301
  register(window.lis.modulePath + "/manifest/service-worker.js", {
    registrationOptions: { scope: window.lis.basePath },
    ready(registration) {
      console.log("Service worker: ready");
    },
    registered(registration) {
      console.log("Service worker: registered");
    },
    cached(registration) {
      console.log("Service worker: cached");
    },
    updatefound(registration) {
      console.log("Service worker: updatefound");
    },
    updated(registration) {
      console.log("Service worker: updated");
    },
    offline() {
      console.log("Service worker: offline");
    },
    error(error) {
      console.log("Service worker: error");
    }
  });
};

// All PWA specific features are only available for touch devices.
// This is obviously wrong in many ways, but anyway.
if (isTouchDevice()) apply();
