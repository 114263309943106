import { parse as parseVcard, generate as generateVcard } from "vcard-parser";
import { $, create } from "./dom.js";
import QRCode from "qrcode";
import escapeHtml from "escape-html";
import { existsInMyContacts, addToMyContacts, removeFromMyContacts } from "./lisStorageUtils.js";

export const downloadVcardText = (vcardText, firstname, lastname) => {
  const a = document.createElement("a");
  a.href = `data:text/x-vcard;base64,${btoa(vcardText)}`;
  a.target = "_blank";
  a.download = `${new Date().toISOString()}--${getFilenameSafeFullName({ firstname, lastname })}.vcf`;
  a.click();
};

const sanitize = token =>
  token
    .trim()
    .replace(/\s+/g, "-")
    .normalize("NFD") // https://stackoverflow.com/a/37511463/72478
    .replace(/[\u0300-\u036f]/g, "") // https://stackoverflow.com/a/37511463/72478
    .toLocaleLowerCase("en-US");

export const getFilenameSafeFullName = profile => `${sanitize(profile.firstname)}-${sanitize(profile.lastname)}`;

export const facebookBase = "https://www.facebook.com/";
export const sanitizeFacebookUrl = facebookUrl => facebookUrl.replace(/.*facebook\.com\//, "");

export const twitterBase = "https://twitter.com/";
export const sanitizeTwitterUrl = twitterUrl => twitterUrl.replace(/.*twitter\.com\//, "");

export const linkedinBase = "https://www.linkedin.com/";
export const sanitizeLinkedinUrl = linkedinUrl => linkedinUrl.replace(/.*linkedin\.com\//, "");

export const generateVcardText = profile => {
  // Mandatory fields.
  const vcardData = {
    version: [{ value: "3.0" }],
    n: [{ value: [profile["lastname"], profile["firstname"], "", "", ""] }],
    "x-socialprofile": [] // For use later on.
  };
  // Optional fields.
  if (profile["company"]) {
    vcardData.org = [{ value: profile["company"] }];
  }
  if (profile["position"]) {
    vcardData.title = [{ value: profile["position"] }];
  }
  if (profile["email"]) {
    vcardData.email = [{ value: profile["email"] }];
  }
  if (profile["phone"]) {
    vcardData.tel = [{ value: profile["phone"] }];
  }
  if (profile["location"]) {
    // Not corrent mapping but maybe the best out of: https://tools.ietf.org/html/rfc6350#section-6.3.1
    vcardData.adr = [{ value: ["", "", "", "", profile["location"], "", ""] }];
  }
  if (profile["facebook"]) {
    vcardData["x-socialprofile"].push({
      meta: { type: ["facebook"] },
      value: facebookBase + profile["facebook"]
    });
  }
  if (profile["twitter"]) {
    vcardData["x-socialprofile"].push({
      meta: { type: ["twitter"] },
      value: twitterBase + profile["twitter"]
    });
  }
  if (profile["linkedin"]) {
    vcardData["x-socialprofile"].push({
      meta: { type: ["linkedin"] },
      value: linkedinBase + profile["linkedin"]
    });
  }
  if (profile["website"]) {
    vcardData.url = [{ value: profile["website"] }];
  }
  return generateVcard(vcardData);
};

export const isVcard = vcardText =>
  vcardText.trim().startsWith("BEGIN:VCARD") && vcardText.trim().endsWith("END:VCARD");

export const parseVcardToProfileData = vcard => {
  const vcardModel = parseVcard(vcard);
  const profileData = {};
  if (vcardModel.n && vcardModel.n[0] && vcardModel.n[0].value) profileData.firstname = vcardModel.n[0].value[1];
  if (vcardModel.n && vcardModel.n[0] && vcardModel.n[0].value) profileData.lastname = vcardModel.n[0].value[0];
  if (vcardModel.org && vcardModel.org[0] && vcardModel.org[0].value) profileData.company = vcardModel.org[0].value;
  if (vcardModel.title && vcardModel.title[0] && vcardModel.title[0].value)
    profileData.position = vcardModel.title[0].value;
  if (vcardModel.email && vcardModel.email[0] && vcardModel.email[0].value)
    profileData.email = vcardModel.email[0].value;
  if (vcardModel.tel && vcardModel.tel[0] && vcardModel.tel[0].value) profileData.phone = vcardModel.tel[0].value;
  if (vcardModel.adr && vcardModel.adr[0] && vcardModel.adr[0].value) profileData.location = vcardModel.adr[0].value[4];
  if (Array.isArray(vcardModel["X-SOCIALPROFILE"])) {
    const facebook = vcardModel["X-SOCIALPROFILE"].find(
      socialprofile =>
        socialprofile.meta && Array.isArray(socialprofile.meta.type) && socialprofile.meta.type.includes("facebook")
    );
    if (facebook && facebook.value) profileData.facebook = sanitizeFacebookUrl(facebook.value);
    const twitter = vcardModel["X-SOCIALPROFILE"].find(
      socialprofile =>
        socialprofile.meta && Array.isArray(socialprofile.meta.type) && socialprofile.meta.type.includes("twitter")
    );
    if (twitter && twitter.value) profileData.twitter = sanitizeTwitterUrl(twitter.value);
    const linkedin = vcardModel["X-SOCIALPROFILE"].find(
      socialprofile =>
        socialprofile.meta && Array.isArray(socialprofile.meta.type) && socialprofile.meta.type.includes("linkedin")
    );
    if (linkedin && linkedin.value) profileData.linkedin = sanitizeLinkedinUrl(linkedin.value);
  }
  if (vcardModel.url && vcardModel.url[0] && vcardModel.url[0].value) profileData.website = vcardModel.url[0].value;
  return profileData;
};

export const getFullNameMarkup = profileData =>
  `<strong>${escapeHtml(profileData["lastname"])}</strong> ${escapeHtml(profileData["firstname"])}`;

export const getProfileView = (profileData, options = {}) => {
  const positionAndCompany = [profileData["position"], profileData["company"]].filter(Boolean).join(", ");
  const $view = create(
    `<div>
  <h2 class="name">${getFullNameMarkup(profileData)}</h2>
  ${options.edit ? `<a href="#/menu/my-profile/edit" class="button edit">Edit profile</a>` : ``}
  ${positionAndCompany ? `<p class="positionAndCompany">${escapeHtml(positionAndCompany)}</p>` : ``}
  ${profileData["location"] ? `<p class="location">${escapeHtml(profileData["location"])}</p>` : ``}
  ${
    profileData["email"]
      ? `<p class="email"><a href="mailto:${escapeHtml(profileData["email"])}">${escapeHtml(
          profileData["email"]
        )}</a></p>`
      : ``
  }
  ${
    profileData["phone"]
      ? `<p class="phone"><a href="tel:${escapeHtml(profileData["phone"])}">${escapeHtml(profileData["phone"])}</a></p>`
      : ``
  }
  ${
    profileData["facebook"]
      ? `<p class="facebook"><a target="_blank" rel="external noreferrer noopener" href="${facebookBase}${escapeHtml(
          profileData["facebook"]
        )}">${facebookBase}${escapeHtml(profileData["facebook"])}</a></p>`
      : ``
  }
  ${
    profileData["twitter"]
      ? `<p class="twitter"><a target="_blank" rel="external noreferrer noopener" href="${twitterBase}${escapeHtml(
          profileData["twitter"]
        )}">${twitterBase}${escapeHtml(profileData["twitter"])}</a></p>`
      : ``
  }
  ${
    profileData["linkedin"]
      ? `<p class="linkedin"><a target="_blank" rel="external noreferrer noopener" href="${linkedinBase}${escapeHtml(
          profileData["linkedin"]
        )}">${linkedinBase}${escapeHtml(profileData["linkedin"])}</a></p>`
      : ``
  }
  ${
    profileData["website"]
      ? `<p class="website"><a target="_blank" rel="external noreferrer noopener" href="${escapeHtml(
          profileData["website"]
        )}">${escapeHtml(profileData["website"])}</a></p>`
      : ``
  }
  ${
    options.qr
      ? `<canvas class="qr"></canvas>
  <p>To exchange your contact information with another participant, scan each other's QR codes.
  Learn about our <a href="${window.lis.basePath}legal-notice-and-policies">Privacy policy</a>.</p>`
      : ``
  }
  ${options.download ? `<button class="button download">Download as vCard</button>` : ``}
  ${
    options.save
      ? `<button class="button save"${existsInMyContacts(profileData) ? "disabled" : ""}>Save in My contacts</button>`
      : ``
  }
  ${options.remove ? `<button class="button remove">Remove from My contacts</button>` : ``}
  ${options.scan ? `<p><a href="#/menu/scan-contacts" class="button scan">Scan a contact</a></p>` : ``}
</div>`
  );

  if (options.qr || options.download) {
    const vcardText = generateVcardText(profileData);
    if (options.qr) {
      QRCode.toCanvas($("canvas.qr", $view), vcardText, { margin: 1, scale: 10 });
    }
    if (options.download) {
      $("button.download", $view).addEventListener("click", () => {
        console.log("Downloading profile data as vCard.");
        downloadVcardText(vcardText, profileData["firstname"], profileData["lastname"]);
      });
    }
  }
  if (options.save) {
    $("button.save", $view).addEventListener("click", e => {
      console.log("Saving profile data in My contacts.");
      addToMyContacts(profileData);
      e.target.disabled = true;
      alert("Saved to My contacts");
    });
  }
  if (options.remove) {
    $("button.remove", $view).addEventListener("click", e => {
      if (!confirm("Are you sure you want to remove this from My contacts?")) return;
      console.log("Removing profile data from My contacts.");
      removeFromMyContacts(profileData);
      e.target.disabled = true;
      alert("Removed from My contacts");
    });
  }

  return $view;
};
