import { $, $$, create } from "./dom.js";
import { isNotificationRead } from "./lisStorageUtils.js";

const createModal = unreadUrgentNotificationsCount =>
  create(
    `<div class="UnreadNotificationsModal">
  <a href="#" class="close">Close</a>
  <p>You have <a href="#/menu/notifications">${
    unreadUrgentNotificationsCount === 1
      ? `1 unread urgent notification`
      : `${unreadUrgentNotificationsCount} unread urgent notifications`
  }.</a></p>
</div>
`
  );

const apply = () => {
  // Count unread urgent notifications.
  const unreadUrgentNotificationsCount = Object.values(window.lis.notifications).filter(
    notification => notification.urgent && !isNotificationRead(notification.id)
  ).length;

  // Do not render modal if there are no unread urgent notifications.
  if (unreadUrgentNotificationsCount === 0) return;

  // Create modal.
  const $modal = createModal(unreadUrgentNotificationsCount);

  // Destroy possibly existing previous modal.
  const $existingModal = $(".UnreadNotificationsModal");
  if ($existingModal) {
    window.document.body.removeChild($existingModal);
  }

  // Apply close modal behavior.
  $(".close", $modal).addEventListener("click", e => {
    e.preventDefault();
    window.document.body.removeChild($modal);
  });

  // Apply visit notifications link behavior.
  $("a:not(.close)", $modal).addEventListener("click", e => {
    window.document.body.removeChild($modal);
  });

  // Attach modal on dom.
  window.document.body.appendChild($modal);
};
export default apply;
