export const $ = (selector, scope = window.document) => scope.querySelector(selector);

export const $child = (selector, scope) => Array.prototype.find.call(scope.children, child => child.matches(selector));

export const $$ = (selector, scope = window.document) => Array.from(scope.querySelectorAll(selector));

export const create = html => Array.from(new DOMParser().parseFromString(html, "text/html").body.childNodes)[0];

export const empty = element => {
  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }
};
