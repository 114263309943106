const DRUPAL_NODE_ID_MATCHER = /node-([1-9][0-9]*)/;
export const getNodeId = node => {
  const id = node.id;
  if (!id) return;
  const match = id.match(DRUPAL_NODE_ID_MATCHER);
  if (!match || !match[1]) return;
  return Number(match[1]);
};

/**
 * Returns the year that this lisbon event installation regards (e.g 2024).
 *
 * Current implementation simply uses the already existing value from
 * window.lis.basePath which is /lisbon-addictions-2024/.
 */
export const getEventYearKey = () => (window.lis && window.lis.basePath) || "default";

/**
 * Detects if device is on iOS.
 */
export const isIos = () => /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());

/**
 * Detects if device is in standalone mode.
 */
export const isInStandaloneMode = () => "standalone" in window.navigator && window.navigator.standalone;
