import { $, $$, create } from "./dom.js";
import { hasPreviouslyNotifiedToAddToHomeScreen, setNotifiedToAddToHomeScreen } from "./lisStorageUtils";
import { isIos, isInStandaloneMode } from "./lisDomUtils.js";

const createModal = () =>
  create(
    `<div class="AddToHomeScreenOnIosModal">
  <a href="#" class="close">Close</a>
  <p>
    To install this app on your device, tap the share icon below and then <code>Add to Home Screen</code>
  </p>
</div>
`
  );

const displayInstallPrompt = () => {
  // Create modal.
  const $modal = createModal();

  // Apply close modal behavior.
  $(".close", $modal).addEventListener("click", e => {
    e.preventDefault();
    window.document.body.removeChild($modal);
  });

  // Attach modal on dom.
  window.document.body.appendChild($modal);
};

// Original implementation from:
// https://www.netguru.com/codestories/few-tips-that-will-make-your-pwa-on-ios-feel-like-native
export default () => {
  // Checks if should display install popup notification:
  if (isIos() && !isInStandaloneMode() && !hasPreviouslyNotifiedToAddToHomeScreen()) {
    displayInstallPrompt();
    setNotifiedToAddToHomeScreen();
  }
};
