import { $, $$, create } from "./dom.js";
import escapeHtml from "escape-html";
import { getFavouritesFull, notifiedUserAboutFavourite } from "./lisStorageUtils.js";

// Starting within the next X minutes.
const startingSoonSecondsBefore = 10 * 60;
// Has started at most 5 minutes ago.
const startingSoonSecondsAfter = 5 * 60;

const intervalCheckSeconds = 2000;

const createModal = favourites =>
  create(
    `<div class="FavouritesNotificationsModal">
  <a href="#" class="close">Close</a>
  <p>${
    favourites.length === 1
      ? `The following <a href="#/menu/my-favourites">favourited session/presentation</a> is starting very soon:`
      : `The following <a href="#/menu/my-favourites">favourited sessions/presentations</a> are starting very soon:`
  }
  <ul>${favourites.map(favourite => `<li>${escapeHtml(favourite.title)}</li>`).join("")}
  </ul>
</div>
`
  );

const apply = () => {
  const now = Math.floor(new Date().getTime() / 1000);
  const favouritesForModal = Object.values(getFavouritesFull()).filter(
    favourite =>
      // Starts within the next 10 minutes and has not started more than 5 minutes ago.
      favourite.startUtc - startingSoonSecondsBefore < now &&
      now < favourite.startUtc + startingSoonSecondsAfter &&
      // We haven't notified the user about it.
      favourite.notifiedUser === undefined
  );

  // Do not render modal if there are no favourited presentations for modal.
  if (favouritesForModal.length === 0) return;

  // Mark them in localStorage so we don't notify user again.
  favouritesForModal.forEach(favourite => notifiedUserAboutFavourite(favourite.id));

  // Create modal.
  const $modal = createModal(favouritesForModal);

  // Play sound.
  playNotificationSound();

  // Destroy possibly existing previous modal.
  const $existingModal = $(".FavouritesNotificationsModal");
  if ($existingModal) {
    window.document.body.removeChild($existingModal);
  }

  // Apply close modal behavior.
  $(".close", $modal).addEventListener("click", e => {
    e.preventDefault();
    window.document.body.removeChild($modal);
  });

  // Apply visit modal link behavior.
  $("a:not(.close)", $modal).addEventListener("click", e => {
    window.document.body.removeChild($modal);
  });

  // Attach modal on dom.
  window.document.body.appendChild($modal);
};

setInterval(apply, intervalCheckSeconds);

const playNotificationSound = () => {
  // https://stackoverflow.com/a/51657751/72478
  setTimeout(() => {
    new Audio(window.lis.modulePath + "/assets/notification.mp3").play();
  }, 50);
};

export default apply;
