import { get as rawStorageGet, set as rawStorageSet, remove as rawStorageRemove } from "./storage.js";
import { getEventYearKey } from "./lisDomUtils.js";
import equal from "fast-deep-equal";

const enhanceKey = key => getEventYearKey() + "-" + key;
const get = key => rawStorageGet(enhanceKey(key));
const set = (key, value) => rawStorageSet(enhanceKey(key), value);
const remove = key => rawStorageRemove(enhanceKey(key));

export const getFavourites = () => {
  const favourites = get("favourites");
  return favourites ? favourites : {};
};

export const getFavouritesFull = () => {
  const favourites = getFavourites();
  Object.entries(favourites).forEach(favourite => {
    const id = Number(favourite[0]);
    const value = favourite[1];
    favourites[id] = enhanceFavourite(id, value);
  });
  return favourites;
};

const enhanceFavourite = (id, value) => {
  const favouritePresentationsWithData = window.lis.presentations.find(presentation => presentation.id === id);
  const favouriteSessionsWithData = window.lis.sessions.find(session => session.id === id);
  return { ...favouriteSessionsWithData, ...favouritePresentationsWithData, ...value };
};

export const getFavourite = id => getFavourites()[id];

export const isFavourite = id => getFavourite(id) !== undefined;

export const favourite = id => {
  const favourites = getFavourites();
  favourites[id] = { addedAtUtc: Math.round(new Date().getTime() / 1000) };
  set("favourites", favourites);
};

export const notifiedUserAboutFavourite = id => {
  const favourites = getFavourites();
  if (!favourites[id]) return; // Should not easily happen.
  favourites[id].notifiedUser = Math.round(new Date().getTime() / 1000);
  set("favourites", favourites);
};

export const unfavourite = id => {
  const favourites = getFavourites();
  delete favourites[id];
  set("favourites", favourites);
};

export const areUrgentNotificationsEnabled = () =>
  get("urgentNotificationsEnabled") === undefined || get("urgentNotificationsEnabled");

export const setUrgentNotifications = enabled => set("urgentNotificationsEnabled", enabled);

export const getReadNotifications = () => {
  const readNotifications = get("readNotifications");
  return readNotifications ? readNotifications : {};
};

export const isNotificationRead = id => getReadNotifications()[id] !== undefined;

export const readNotification = id => {
  const readNotifications = getReadNotifications();
  readNotifications[id] = { readAtUtc: Math.round(new Date().getTime() / 1000) };
  set("readNotifications", readNotifications);
};

export const hasProfileData = () => Object.keys(getProfileData()).length > 0;
export const getProfileData = () => get("profile") || {};
export const setProfileData = value => set("profile", value);
export const removeProfileData = () => remove("profile");

export const setLastMenuScrollTop = top => set("lastMenuScrollTop", top);
export const getLastMenuScrollTop = () => get("lastMenuScrollTop") || 0;
export const setLastMenuPage = page => set("lastMenuPage", page);
export const getLastMenuPage = () => get("lastMenuPage");

export const getMyContacts = () => get("myContacts") || [];
export const addToMyContacts = profileData => set("myContacts", [...getMyContacts(), profileData]);
export const existsInMyContacts = profileData =>
  getMyContacts().findIndex(contact => equal(contact, profileData)) !== -1;
export const removeFromMyContacts = profileData => {
  const myContacts = getMyContacts();
  const firstMatchIndex = myContacts.findIndex(contact => equal(contact, profileData));
  if (firstMatchIndex === -1) return;
  myContacts.splice(firstMatchIndex, 1);
  set("myContacts", myContacts);
};

export const hasPreviouslyNotifiedToAddToHomeScreen = () => get("notifiedToAddToHomeScreen") === true;
export const setNotifiedToAddToHomeScreen = () => set("notifiedToAddToHomeScreen", true);
